import React from 'react'
import { Link } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import { Menu } from 'react-feather';
export default function Header({ theme, position }) {
    return (
        <header className={[position, "w-full z-50 px-4"].join(" ")}>
            <div className="container mx-auto py-5">
                <div className="flex flex-stretch items-center">
                    <div className="w-56 items-center flex">
                        <Link to="/">
                            <img
                                className='max-h-10'
                                src="/images/content2/logo.png"
                                alt="El-Store Logo"
                            />
                        </Link>
                    </div>
                    <div className="w-full"></div>
                    <div className="w-auto">
                        <ul
                            className="fixed bg-white inset-0 flex flex-col invisible items-center justify-center opacity-0 sm:visible sm:flex-row sm:bg-transparent sm:relative sm:opacity-100 sm:flex sm:items-center"
                            id="menu"
                        >
                            <li className="mx-3 py-6 sm:py-0">
                                {/* eslint-disable jsx-a11y/anchor-is-valid */}
                                <NavLink to="/order" className={["hover:underline", theme === "white" ? "text-black sm:text-white" : "text-white sm:text-black"].join(" ")}>Encomendar</NavLink>
                            </li>
                            <li className="mx-3 py-6 sm:py-0">
                                <NavLink to="/showroom" className={["hover:underline", theme === "white" ? "text-black sm:text-white" : "text-white sm:text-black"].join(" ")}>Showroom</NavLink>
                            </li>
                            <li className="mx-3 py-6 sm:py-0">
                                <NavLink to="/contacts" className={["hover:underline", theme === "white" ? "text-black sm:text-white" : "text-white sm:text-black"].join(" ")}>Contactos</NavLink>
                            </li>
                        </ul>
                    </div>
                    <div className="w-auto">
                        <ul className="items-center flex">
                            <li className="ml-6 block sm:hidden">
                                <button
                                    id="menu-toggler"
                                    className="relative flex z-50 items-center justify-center w-16 h-8 text-black md:text-white focus:outline-none"
                                    onClick={() => console.log("clicked")}
                                >
                                    <Menu />
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </header>
    )
}
