import React from 'react'
import { FaPhoneAlt, FaEnvelope, FaHome, FaInstagram, FaFacebook } from 'react-icons/fa';
import ContactSection from '../components/ContactSection/ContactSection';

export default function Home() {
    return (
        <section className="flex-col py-16 px-4 w-full" id="browse-the-room">
            <div >
                <img
                className='max-h-20 mx-auto'
                src="/images/content2/logo.png"
                alt="El-Store Logo"
            />
            </div>
            <div className='text-3xl font-bold'>Contacte-nos</div>
            <div className='flex w-full justify-around'>
                <ContactSection Title="Telefone" Icon={FaPhoneAlt} Text={"+351 919 191 919"} />
                <ContactSection Title="Email" Icon={FaEnvelope} Text={"teste@email.com"} />
                <ContactSection Title="Morada" Icon={FaHome} Text={"R. Sitio do picapau amarelo n.0"} />
                <ContactSection Title="Instagram" Icon={FaInstagram} Text={"+351 919 191 919"} />
                <ContactSection Title="Facebook" Icon={FaFacebook} Text={"+351 919 191 919"} />
            </div>
        </section>
    )
}
