import React from 'react'
import useScrollAnchors from '../helpers/hooks/useScrollAnchors'
import useModalDom from '../helpers/hooks/useModalDom'

export default function Home() {
    useScrollAnchors();
    useModalDom();
    return (
        <div>
           Showroom
        </div>
    )
}
